<template>
  <CHeader with-subheader>
    <CToggler
      in-header
      class="ml-2 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CHeaderBrand class="mx-auto d-lg-none" to="/">
      <img class="c-sidebar-brand-full" src="/brand/logo.svg" width="135" alt="Gecco Logo">
    </CHeaderBrand>
    <CHeaderNav class="ml-lg-auto">
      <CHeaderNavItem class="px-lg-3">
        <TheHeaderDropdownNotif/>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-lg-3 d-md-down-none" v-if="introJsEnabled" id="btnIntroJs">
        <i v-on:click="introJs.start()" class="icon-question cursor-pointer"></i>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-lg-3 mr-2">
        <TheHeaderDropdownAccnt/>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-lg-3" v-if="sidebarNav.length > 0">
        <button
          in-header
          class="c-header-nav-btn"
          @click="$store.commit('toggle', 'asideShow')">
          <span class="c-header-toggler-icon" style="width: 1.5rem; height: 1.5rem;"></span>
        </button>
      </CHeaderNavItem>
    </CHeaderNav>

    <CSubheader class="px-3" v-if="container">
      <CBreadcrumbRouter class="border-0 mb-0"/>
    </CSubheader>
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'
import TheHeaderDropdownNotif from './TheHeaderDropdownNotif'
import introJs from '@/shared/intro.js';

export default {
  name: 'TheHeader',
  components: {
    TheHeaderDropdownAccnt,
    TheHeaderDropdownNotif,
  },
  props: {
    container: {
      type: Boolean,
    },
  },
  data () {
    return {
      introJs: introJs,
    }
  },
  computed: {
    introJsEnabled(){
      return this.$store.getters.getIntroJsEnabled
    },
    sidebarNav () {
      return this.$store.getters.getSidebarNav
    },
  },
}
</script>
