<template>
  <CSidebar
    :minimize="minimize"
    unfoldable
    color-scheme="light"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])">
    <CSidebarBrand class="d-md-down-none" to="/">
      <img class="c-sidebar-brand-full" src="/brand/logo.svg" width="135" alt="Gecco Logo">
      <img class="c-sidebar-brand-minimized" src="/brand/logo-symbol.svg" width="30" alt="Gecco Logo">
    </CSidebarBrand>
    <CRenderFunction flat :contentToRender="nav"/>
    <CSidebarMinimizer
      class="c-d-md-down-none"
      @click.native="$store.commit('toggle', 'sidebarMinimize')"
    />
  </CSidebar>
</template>

<script>
import navigation from './_nav'

export default {
  name: 'TheSidebar',
  data () {
    return {
      nav: [],
    }
  },
  computed: {
    show () {
      return this.$store.state.sidebarShow
    },
    minimize () {
      return this.$store.state.sidebarMinimize
    }
  },
  created: function () {
    this.user = this.$store.getters.getUser;
    this.nav = this.navRecursion(navigation);
  },
  methods: {
    navRecursion: function(nav_items){
      let self = this;
      let nav = [];
      nav_items.forEach(function (nav_item, key) {
        if(typeof(nav_item.meta) !== 'undefined'){
          let allowed = false;
          nav_item.meta.permissions.forEach(function (permission) {
            if(self.user.permissions.indexOf(permission) !== -1){
              allowed = true;
            }
          });

          if(allowed){
            nav.push({
              _name: nav_item._name,
              name: nav_item.name,
              icon: nav_item.icon,
              to: nav_item.to,
              href: nav_item.href,
              target: nav_item.target,
              fontIcon: nav_item.fontIcon,
              class: nav_item.class,
              attributes: nav_item.attributes,
              _children: (typeof(nav_item._children) !== 'undefined') ? (typeof(nav_item._children[0]) === 'string' ? nav_item._children : self.navRecursion(nav_item._children)) : null
            });
          }
        }
        else {
          nav.push({
            _name: nav_item._name,
            name: nav_item.name,
            icon: nav_item.icon,
            to: nav_item.to,
            href: nav_item.href,
            target: nav_item.target,
            fontIcon: nav_item.fontIcon,
            class: nav_item.class,
            attributes: nav_item.attributes,
            _children: (typeof(nav_item._children) !== 'undefined') ? (typeof(nav_item._children[0]) === 'string' ? nav_item._children : self.navRecursion(nav_item._children)) : null
          });
        }
      });
      return nav;
    },
  }
}
</script>

<style>
  .c-sidebar .c-sidebar-nav > li.c-sidebar-nav-item:last-of-type {
    margin-top: auto;
  }
  .c-sidebar-right .c-sidebar-nav > li.c-sidebar-nav-item:last-of-type {
    margin-top: 0;
  }
</style>

