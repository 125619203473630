<template>
  <CFooter :fixed="false">
    <div>
      <a href="https://www.gecco-fahrschulen.de/" target="_blank">&copy; Gecco Media GmbH | All rights reserved</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
