<template>
  <CDropdown
    v-if="itemsCount.count > 0"
    placement="bottom-end"
    :caret="false"
    :show.sync="show"
    in-nav
    class="c-header-nav-item"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <CIcon name="cil-bell"/>
        <CBadge shape="pill" color="danger" v-if="itemsCount.important > 0">{{ itemsCount.important }}</CBadge>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader
      tag="div"
      class="text-center bg-light"
    >
      <strong>Du hast {{ itemsCount.count }} ToDo's</strong>
    </CDropdownHeader>
    <CDropdownItem v-for="(item, key) in items" :key="key" @click="itemClick(item.link)">
      <div class="message">
        <div class="text-truncate font-weight-bold">
          <CIcon name="cil-warning" class="text-danger" v-if="item.important"/>
          {{ item.title }}
        </div>
        <div class="small text-muted text-truncate" v-html="item.text"></div>
      </div>
    </CDropdownItem>
    <CDropdownItem v-if="items.length === 0">
      <div class="message" style="width: 275px;">
        <div class="progress">
          <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
        </div>
      </div>
    </CDropdownItem>
  </CDropdown>
</template>

<script>
import Http from '@/shared/http-config'

export default {
  name: 'TheHeaderDropdownNotif',
  data () {
    return {
      show: false,
      items: [],
      itemsCount: {
        count: 0,
        important: 0
      }
    }
  },
  watch: {
    show: function (value) {
      if(value === true && this.items.length === 0){
        this.getTodo()
      }
    }
  },
  created: function() {
    let self = this;
    self.checkTodo();
    setInterval(function () {
      self.checkTodo();
    }, 900000)
  },
  methods: {
    checkTodo: function () {
      let self = this;
      Http.request('GET', '/user/notifications/check', null, false).then((notifications) => {
        if(
          self.itemsCount.count !== notifications.data.count ||
          self.itemsCount.important !== notifications.data.important
        ){
          self.show = false;
          self.items = [];
        }
        self.itemsCount = notifications.data;
      });
    },
    getTodo: function () {
      let self = this;
      Http.request('GET', '/user/notifications', null, false).then((notifications) => {
        self.items = notifications.data;
      });
    },
    itemClick: function (url) {
      if(url.indexOf('/login/') === 0){
        this.$router.push('/frame/' + window.btoa(url)).catch(err => {});
      }
      else if(url.indexOf('http') === 0) {
        window.open(url, '_blank');
      }
      else {
        this.$router.push(url).catch(err => {});
      }
    }
  }
}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
</style>
