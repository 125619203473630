export default [{
  _name: 'CSidebarNav',
  _children: [
    {
      _name: 'CSidebarNavItem',
      name: 'Dashboard',
      to: '/dashboard',
      icon: 'cil-speedometer'
    },
    {
      _name: 'CSidebarNavItem',
      name: 'Zum alten Login',
      to: '/login/?action=old',
      fontIcon: 'icon-action-undo',
      target: '_blank',
    },
    {
      _name: 'CSidebarNavTitle',
      _children: ['GECCO System'],
    },
    {
      _name: 'CSidebarNavItem',
      name: 'Partnerverwaltung',
      to: '/clients/overview',
      icon: 'cil-people',
      meta: {
        permissions: ['customer_admin']
      },
    },
    {
      _name: 'CSidebarNavDropdown',
      name: 'KBC',
      route: '/customers',
      icon: 'cil-people',
      meta: {
        permissions: ['kbc']
      },
      _children: [
        {
          _name: 'CSidebarNavItem',
          name: 'Schülerübersicht',
          to: '/customers/overview'
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Preislisten',
          to: '/customers/price',
        },
        {
          _name: 'CSidebarNavItem',
          name: 'ABC Modelle',
          to: '/customers/abc',
          meta: {
            permissions: ['abc_packet']
          },
        },
        {
          _name: 'CSidebarNavDropdown',
          name: 'Beratung 2.0',
          route: '/customers/consultation',
          meta: {
            permissions: ['consultation']
          },
          _children: [
            {
              _name: 'CSidebarNavItem',
              name: '- Übersicht',
              to: '/customers/consultation/overview',
            },
            {
              _name: 'CSidebarNavItem',
              name: '- Einstellungen',
              to: '/customers/consultation/settings',
            },
          ]
        },
        {
          _name: 'CSidebarNavDropdown',
          name: 'FunLearn',
          route: '/customers/funlearn',
          meta: {
            permissions: ['funlearn']
          },
          _children: [
            {
              _name: 'CSidebarNavItem',
              name: '- Übersicht',
              to: '/customers/funlearn/overview',
            },
            {
              _name: 'CSidebarNavItem',
              name: '- Einstellungen',
              to: '/customers/funlearn/settings',
            },
          ]
        },
      ]
    },
    {
      _name: 'CSidebarNavDropdown',
      name: 'Shop',
      icon: 'cil-tags',
      route: '/shop',
      meta: {
        permissions: ['shop']
      },
      _children: [
        {
          _name: 'CSidebarNavItem',
          name: 'Shop',
          to: '/shop/overview',
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Meine Bestellungen',
          to: '/shop/order',
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Gutscheinkonto',
          to: '/shop/voucher-account',
        },
      ]
    },
    {
      _name: 'CSidebarNavDropdown',
      name: 'Handbuch',
      route: '/km',
      fontIcon: 'icon-book-open',
      meta: {
        permissions: ['km_manual','km_manual_admin']
      },
      _children: [
        {
          _name: 'CSidebarNavItem',
          name: 'Übersicht',
          to: '/km/overview',
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Eigenes Konzept',
          to: '/km/new-km',
          meta: {
            permissions: ['km_manual']
          },
        },
      ],
    },
    {
      _name: 'CSidebarNavDropdown',
      name: 'Erfolgscenter',
      fontIcon: 'icon-star',
      route: '/controlling',
      meta: {
        permissions: ['controlling','controlling_admin','marketing_evaluation']
      },
      _children: [
        {
          _name: 'CSidebarNavItem',
          name: 'Dashboard',
          to: '/controlling/overview',
          meta: {
            permissions: ['controlling','controlling_admin']
          },
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Monatliche Eingaben',
          to: '/controlling/values',
          meta: {
            permissions: ['controlling']
          },
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Werbemittelmessung',
          to: '/controlling/advertising',
          meta: {
            permissions: ['marketing_evaluation']
          },
        },
      ]
    },
    {
      _name: 'CSidebarNavDropdown',
      name: 'Stammdaten',
      icon: 'cil-user',
      route: '/data',
      meta: {
        permissions: ['cms_settings','data','employee_survey_evaluation','cms_admin', 'cms_usp']
      },
      _children: [
        {
          _name: 'CSidebarNavItem',
          name: 'Alleinstellungsmerkmale',
          to: '/data/usp',
          meta: {
            permissions: ['cms_usp']
          },
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Klassen',
          to: '/data/classes',
        },
        {
          _name: 'CSidebarNavDropdown',
          name: 'Team',
          route: '/data/team',
          meta: {
            permissions: ['data','employee_survey_evaluation','cms_admin']
          },
          _children: [
            {
              _name: 'CSidebarNavItem',
              name: '- Übersicht',
              to: '/data/team/team',
              meta: {
                permissions: ['data','cms_admin']
              },
            },
            {
              _name: 'CSidebarNavItem',
              name: '- Mitarbeiterbefragung',
              to: '/data/team/survey',
              meta: {
                permissions: ['employee_survey_evaluation']
              },
            },
          ]
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Fuhrpark',
          to: '/data/cars',
          meta: {
            permissions: ['data','cms_admin']
          },
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Filialen',
          to: '/data/branches',
          meta: {
            permissions: ['data','cms_admin']
          },
        },
        {
          _name: 'CSidebarNavDropdown',
          name: 'Einstellungen',
          route: '/data/settings',
          meta: {
            permissions: ['cms_settings']
          },
          _children: [
            {
              _name: 'CSidebarNavItem',
              name: '- Emails',
              to: '/data/settings/emails',
            },
            {
              _name: 'CSidebarNavItem',
              name: '- Domain',
              to: '/data/settings/domain',
            },
          ]
        },
        {
          _name: 'CSidebarNavDropdown',
          name: 'Accounts',
          route: '/data/employees',
          meta: {
            permissions: ['employee_logins']
          },
          _children: [
            {
              _name: 'CSidebarNavItem',
              name: '- Übersicht',
              to: '/data/employees/overview',
            },
            {
              _name: 'CSidebarNavItem',
              name: '- Rollen',
              to: '/data/employees/permissions',
            },
          ]
        },
      ]
    },
    {
      _name: 'CSidebarNavDropdown',
      name: 'Hilfe & Kontakt',
      route: '/help',
      fontIcon: 'icon-question',
      _children: [
        {
          _name: 'CSidebarNavItem',
          name: 'FAQ',
          to: '/help/faq',
          meta: {
            permissions: ['tickets']
          },
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Meine Fragen',
          to: '/help/tickets',
          meta: {
            permissions: ['tickets']
          },
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Tickets Master',
          to: '/help/tickets-admin',
          meta: {
            permissions: ['tickets_admin']
          },
        },
        {
          _name: 'CSidebarNavItem',
          name: 'FAQs Admin',
          to: '/help/faq-admin',
          meta: {
            permissions: ['tickets_admin']
          },
        },
      ],
    },
    {
      _name: 'CSidebarNavTitle',
      _children: ['GECCO Family'],
    },
    {
      _name: 'CSidebarNavDropdown',
      name: 'Events',
      icon: 'cil-speech',
      route: '/events',
      meta: {
        permissions: ['events']
      },
      _children: [
        {
          _name: 'CSidebarNavItem',
          name: 'Übersicht',
          to: '/events',
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Deine Buchungen',
          to: '/events/booked',
        },
      ]
    },
    {
      _name: 'CSidebarNavTitle',
      _children: ['GECCO Marketing'],
    },
    {
      _name: 'CSidebarNavDropdown',
      name: 'Meine Webseite',
      fontIcon: 'icon-globe',
      route: '/website',
      meta: {
        permissions: ['cms_admin','cms_superadmin','website_evaluation']
      },
      _children: [
        {
          _name: 'CSidebarNavItem',
          name: 'Homepage',
          to: '/website/cms',
          meta: {
            permissions: ['cms_admin','cms_superadmin']
          }
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Kundenstimmen',
          to: '/website/guestbook',
          meta: {
            permissions: ['cms_admin']
          },
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Kurse & Seminare',
          to: '/website/courses',
          meta: {
            permissions: ['cms_admin']
          },
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Stellenangebote',
          to: '/website/jobs',
          meta: {
            permissions: ['website_jobs']
          },
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Bildergalerie',
          to: '/website/galleries',
          meta: {
            permissions: ['cms_admin']
          },
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Aktuelles',
          to: '/website/news',
          meta: {
            permissions: ['cms_admin']
          },
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Einstellungen',
          to: '/website/settings',
          meta: {
            permissions: ['cms_settings']
          },
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Formulare',
          to: '/website/forms',
          meta: {
            permissions: ['cms_superadmin']
          }
        },
        {
          _name: 'CSidebarNavDropdown',
          name: 'BKF',
          route: '/website/bkf',
          meta: {
            permissions: ['cms_admin']
          },
          _children: [
            {
              _name: 'CSidebarNavItem',
              name: '- Zertifizierung',
              to: '/website/bkf/certification',
            },
          ]
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Statistiken',
          to: '/website/stats',
          meta: {
            permissions: ['website_evaluation']
          },
        },
      ]
    },
    {
      _name: 'CSidebarNavDropdown',
      name: 'Marketing',
      icon: 'cil-layers',
      route: '/marketing',
      meta: {
        permissions: ['storewindow','society','mailings','public_relations','newsletter','feedback_evaluation','facebook']
      },
      _children: [
        {
          _name: 'CSidebarNavItem',
          name: 'Medien Manager',
          to: '/marketing/media-manager',
          meta: {
            permissions: ['facebook']
          },
        },
        {
          _name: 'CSidebarNavDropdown',
          name: 'Schaufenster',
          route: '/marketing/store-window',
          meta: {
            permissions: ['storewindow']
          },
          _children: [
            {
              _name: 'CSidebarNavItem',
              name: '- Übersicht',
              to: '/marketing/store-window/overview',
            },
            {
              _name: 'CSidebarNavItem',
              name: '- Seiten',
              to: '/marketing/store-window/storewindow-pages',
            },
          ],
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Vereinskooperation',
          to: '/marketing/society',
          meta: {
            permissions: ['society']
          },
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Mailings',
          to: '/marketing/mailings',
          meta: {
            permissions: ['mailings']
          },
        },
        {
          _name: 'CSidebarNavDropdown',
          name: 'Presseartikel',
          route: '/marketing/pr',
          meta: {
            permissions: ['public_relations']
          },
          _children: [
            {
              _name: 'CSidebarNavItem',
              name: '- Übersicht',
              to: '/marketing/pr/overview',
            },
            {
              _name: 'CSidebarNavItem',
              name: '- Presseverteiler',
              to: '/marketing/pr/distributor',
            },
          ],
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Newsletter',
          to: '/marketing/newsletter',
          meta: {
            permissions: ['newsletter']
          },
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Auswertung Feedback',
          to: '/marketing/feedback-evaluation',
          meta: {
            permissions: ['feedback_evaluation']
          },
        },
      ]
    },
    {
      _name: 'CSidebarNavDropdown',
      name: 'Camps',
      icon: 'cil-speedometer',
      route: '/camps',
      meta: {
        permissions: ['camps','camps_admin']
      },
      _children: [
        {
          _name: 'CSidebarNavDropdown',
          name: 'Führerscheincamp',
          route: '/camps/fcamp',
          _children: [
            {
              _name: 'CSidebarNavItem',
              name: '- Kurse',
              to: '/camps/fcamp/course',
              meta: {
                permissions: ['camps']
              },
            },
            {
              _name: 'CSidebarNavItem',
              name: '- Willkommensschreiben',
              to: '/camps/fcamp/welcome',
              meta: {
                permissions: ['camps']
              },
            },
            {
              _name: 'CSidebarNavItem',
              name: '- Angaben Beratung',
              to: '/camps/fcamp/phone',
              meta: {
                permissions: ['camps']
              },
            },
            {
              _name: 'CSidebarNavItem',
              name: '- Ausbildungsvertrag',
              to: '/camps/fcamp/contract',
              meta: {
                permissions: ['camps']
              },
            },
            {
              _name: 'CSidebarNavItem',
              name: '- Camp Hotline',
              to: '/camps/fcamp/hotline',
              meta: {
                permissions: ['camps_admin']
              },
            },
          ]
        },
        {
          _name: 'CSidebarNavDropdown',
          name: 'Motorradcamp',
          route: '/camps/mcamp',
          _children: [
            {
              _name: 'CSidebarNavItem',
              name: '- Kurse',
              to: '/camps/mcamp/course',
              meta: {
                permissions: ['camps']
              },
            },
            {
              _name: 'CSidebarNavItem',
              name: '- Willkommensschreiben',
              to: '/camps/mcamp/welcome',
              meta: {
                permissions: ['camps']
              },
            },
            {
              _name: 'CSidebarNavItem',
              name: '- Angaben Beratung',
              to: '/camps/mcamp/phone',
              meta: {
                permissions: ['camps']
              },
            },
            {
              _name: 'CSidebarNavItem',
              name: '- Ausbildungsvertrag',
              to: '/camps/mcamp/contract',
              meta: {
                permissions: ['camps']
              },
            },
            {
              _name: 'CSidebarNavItem',
              name: '- Camp Hotline',
              to: '/camps/mcamp/hotline',
              meta: {
                permissions: ['camps_admin']
              },
            },
          ]
        },
      ]
    },
    {
      _name: 'CSidebarNavItem',
      name: 'Webmail',
      href: 'https://webmail.fahrschul-system.de',
      target: '_blank',
      fontIcon: 'icon-envelope-letter',
      class: 'mt-auto',
      attributes: { target: '_blank', rel: 'noopener' }
    },
  ]
}]
